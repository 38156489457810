.dark-content {
  $input-placeholder-color: $white;

  hr {
    border-top: 1px solid rgba($white, 0.075);
  }

  .modal {
    background: linear-gradient(rgb(0, 0, 0), rgba($black, 0.75));
  }

  .modal-content {
    // background: linear-gradient(rgb(12, 12, 12), rgba($white, 0.075));
  }

  // Media Responsiveness
  @media screen and (max-height: 768px) {
    .table-responsive {
      height: 450px;
    }
  }

  @media screen and (min-height: 769px) {
    .table-responsive {
      height: 600px;
    }
  }

  // Custom Scrollbar
  .table-responsive {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar-corner {
      display: none;
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgb(7, 89, 133);
    }
  }
  .webmer_structure_dash_operation_page .card {
    background: #1a1a2c;
  }

  mat-tab-group {
    .mat-tab-label-content {
      color: $white;
    }
  }
}
