.fade-in-1s {
    animation: fadeIn ease-in-out 1s;
    -webkit-animation: fadeIn ease-in-out 1s;
    -moz-animation: fadeIn ease-in-out 1s;
    -o-animation: fadeIn ease-in-out 1s;
    -ms-animation: fadeIn ease-in-out 1s;
}

.fade-in-2s {
    animation: fadeIn ease-in-out 2s;
    -webkit-animation: fadeIn ease-in-out 2s;
    -moz-animation: fadeIn ease-in-out 2s;
    -o-animation: fadeIn ease-in-out 2s;
    -ms-animation: fadeIn ease-in-out 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}