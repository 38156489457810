/* You can add global styles to this file, and also import other style files */
.tippy-popper.tomato-theme {
  background-color: tomato;
  color: yellow;
}
.swal2-icon-content {
  display: none !important;
}
.toast-container {
  max-width: 67%;
  font-size: smaller;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-close-button {
  padding: 1.5rem;
}
.gm-style img {
  max-width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3N0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDhFQUFBO0FBRUE7RUFDRSx3QkFBQTtFQUNBLGFBQUE7QUFBRjtBQUdBO0VBQ0Usd0JBQUE7QUFBRjtBQUdBO0VBQ0UsY0FBQTtFQUNBLGtCQUFBO0FBQUY7QUFHQTtFQUNFLHlCQUFBO0VBQ0EscUJBQUE7QUFBRjtBQUdBO0VBQ0UsZUFBQTtBQUFGO0FBR0E7RUFDRSxlQUFBO0FBQUYiLCJmaWxlIjoic3R5bGVzLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvKiBZb3UgY2FuIGFkZCBnbG9iYWwgc3R5bGVzIHRvIHRoaXMgZmlsZSwgYW5kIGFsc28gaW1wb3J0IG90aGVyIHN0eWxlIGZpbGVzICovXG5cbi50aXBweS1wb3BwZXIudG9tYXRvLXRoZW1lIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogdG9tYXRvO1xuICBjb2xvcjogeWVsbG93O1xufVxuXG4uc3dhbDItaWNvbi1jb250ZW50IHtcbiAgZGlzcGxheTogbm9uZSAhaW1wb3J0YW50O1xufVxuXG4udG9hc3QtY29udGFpbmVyIHtcbiAgbWF4LXdpZHRoOiA2NyU7XG4gIGZvbnQtc2l6ZTogc21hbGxlcjtcbn1cblxuLnRvYXN0LW1lc3NhZ2Uge1xuICAtbXMtd29yZC13cmFwOiBicmVhay13b3JkO1xuICB3b3JkLXdyYXA6IGJyZWFrLXdvcmQ7XG59XG5cbi50b2FzdC1jbG9zZS1idXR0b24ge1xuICBwYWRkaW5nOiAxLjVyZW07XG59XG5cbi5nbS1zdHlsZSBpbWcge1xuICBtYXgtd2lkdGg6IDEwMCU7XG59XG5cbiJdfQ== */