.main-panel {
  &,
  &[data='red'] {
    border-color: $danger;
  }
  .footer {
    padding: 15px;
  }
}

.main-panel .content {
  padding: 70px 15px 15px;
}

.logo-img {
  width: 35px;
  height: 35px;
  display: block;
  margin-left: 2px;
  position: absolute;
  top: 12px;
  img {
    width: 35px;
  }
}
.sidebar:before,
.off-canvas-sidebar:before {
  border-bottom: 5px solid $primary;
}
.sidebar,
.off-canvas-sidebar {
  &,
  &[data='red'] {
    @include linear-gradient($danger-states, $danger);
  }
  &[data='primary'] {
    @include linear-gradient($primary-states, $primary);
  }
}
@media screen and (max-width: 991px) {
  .fixed-plugin .dropdown-toggle:after {
    display: none;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .nav-open .rtl .wrapper {
    transform: none;
  }
}
