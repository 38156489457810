/* You can add global styles to this file, and also import other style files */

.tippy-popper.tomato-theme {
  background-color: tomato;
  color: yellow;
}

.swal2-icon-content {
  display: none !important;
}

.toast-container {
  max-width: 67%;
  font-size: smaller;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-close-button {
  padding: 1.5rem;
}

.gm-style img {
  max-width: 100%;
}

