// Core
@import "custom/functions";
@import "bootstrap/functions";

@import "custom/variables";
@import "bootstrap/variables";

@import "custom/mixins";
@import "bootstrap/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Custom components
@import "custom/reboot.scss";
@import "custom/global.scss";
@import "custom/utilities.scss";
@import "custom/alerts.scss";
@import "custom/avatars.scss";
@import "custom/badge.scss";
@import "custom/buttons.scss";
@import "custom/close.scss";
@import "custom/custom-forms.scss";
@import "custom/dropdown.scss";
@import "custom/footer.scss";
@import "custom/forms.scss";
@import "custom/grid.scss";
@import "custom/icons.scss";
@import "custom/images.scss";
@import "custom/input-group.scss";
@import "custom/info-areas.scss";
@import "custom/modal.scss";
@import "custom/nav.scss";
@import "custom/navbar.scss";
@import "custom/pagination.scss";
@import "custom/tabs.scss";
@import "custom/pills.scss";
@import "custom/popover.scss";
@import "custom/progress.scss";
@import "custom/type.scss";
@import "custom/tables";
@import "custom/checkboxes-radio";
@import "custom/fixed-plugin";
@import "custom/sidebar-and-main-panel.scss";
@import "custom/misc.scss";
@import "custom/rtl.scss";
@import "custom/timeline.scss";
@import "custom/spinner.scss";

// Vendor / Plugins
@import "custom/vendor/plugin-datetimepicker.scss";
@import "custom/vendor/plugin-nouislider.scss";
@import "custom/vendor/plugin-fullcalendar.scss";
@import "custom/vendor/plugin-sweetalert2.scss";
@import "custom/vendor/plugin-bootstrap-switch.scss";
@import "custom/vendor/plugin-bootstrap-select.scss";
@import "custom/vendor/plugin-jasny-fileupload.scss";
@import "custom/vendor/plugin-tagsinput.scss";
@import "custom/vendor/plugin-perfect-scrollbar.scss";
@import "custom/vendor/plugin-jquery.jvectormap.scss";
@import "custom/vendor/plugin-datatables.net.scss";
@import "custom/vendor/plugin-animate-bootstrap-notify.scss";
@import "custom/vendor/plugin-card-wizard.scss";
@import "custom/vendor/plugin-chartjs.scss";

// auth pages
@import "custom/auth-pages.scss";

// light mode
@import "custom/white-content.scss";

// dark mode
@import "custom/dark-content.scss";

// animations
@import "custom/animation.scss";

// Cards
@import "custom/card";
@import "custom/cards/card-chart";
@import "custom/cards/card-map";
@import "custom/cards/card-user";
@import "custom/cards/card-task";
@import "custom/cards/card-animation-on-hover";
@import "custom/cards/card-collapse";
@import "custom/cards/card-contributions";
@import "custom/cards/card-lock";
@import "custom/cards/card-pricing";
@import "custom/cards/card-profile";
@import "custom/cards/card-plain";
@import "custom/cards/card-register";
@import "custom/cards/card-stats";
@import "custom/cards/card-subcategories";
@import "custom/cards/card-testimonials";
@import "custom/cards/card-wizard";

// Angular Differences
@import "angular-differences/angular-differences";

// Angular Material
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Custom Angular Material
@import "custom/material";

